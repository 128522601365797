import React, { Component, createRef } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Attachment, { ACCEPTED_TYPES, ATTACHMENT_TYPE } from '@core/models/Attachment';
import { DateFormatter } from '@core/utils/DateTime';
import { generateExportURL } from '@core/utils/Generators';

import { Icon, Loader } from '@components/dmp';
import DropdownDots from '@components/dmp/DropdownDots';

import DealPanelItem from '@components/deal/DealHeader/DealPanelItem';
import TooltipButton from '@components/editor/TooltipButton';
import Fire from '@root/Fire';

const MenuItem = DropdownDots.MenuItem;

@autoBindMethods
export default class DealAttachmentBlock extends Component {
  static propTypes = {
    attachment: PropTypes.instanceOf(Attachment).isRequired,
    interactive: PropTypes.bool,
    downloadable: PropTypes.bool,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
  };

  static defaultProps = {
    onEdit: _.noop,
    onDelete: _.noop,
    interactive: true,
    downloadable: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      downloading: false,
      downloadURL: null,
      dataURL: null,
      attachmentURL: '#',
    };

    this.downloadRef = createRef();
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  get icon() {
    const { attachment } = this.props;
    if (this.state.downloading) return <Loader />;

    const fileType = _.find(ACCEPTED_TYPES, { extension: attachment.extension });
    const iconName = _.get(fileType, 'icon', 'document');

    return (
      <div className="file-icon">
        <Icon name={iconName} />
      </div>
    );
  }

  get date() {
    const { attachment } = this.props;
    return `${DateFormatter.ymd(attachment.date, '-')} @ ${DateFormatter.time(attachment.date)}`;
  }

  async handleAction(action) {
    const { onEdit, onDelete, attachment } = this.props;

    switch (action) {
      case 'edit':
        onEdit(attachment);
        break;
      case 'delete':
        onDelete(attachment);
        break;
      case 'download':
        await this.onDownload();
        break;
      default:
        break;
    }
  }

  async getURL(download = false) {
    const { attachment } = this.props;

    const options = { dealID: attachment.deal.dealID, attachmentID: attachment.key };
    if (download) {
      options.download = true;
    }

    const token = await Fire.token();
    return generateExportURL({
      token,
      type: 'attachment',
      options,
    });
  }

  async onDownload() {
    const url = await this.getURL(true);
    if (this._isMounted) {
      this.setState({ attachmentURL: url }, () => this.downloadRef.current.click());
    }
  }

  async onPreview(e) {
    const url = await this.getURL(false);
    if (this._isMounted) {
      this.setState({ attachmentURL: url }, () => this.downloadRef.current.click());
    }
  }

  render() {
    const { attachment, interactive, downloadable } = this.props;
    const { attachmentURL } = this.state;

    const className = cx('deal-file-block', { interactive }, { downloadable });

    return (
      <DealPanelItem borderBottom className={className}>
        {this.icon}
        <div className="file-info" data-cy="attachment-file-info">
          <div className="file-topline">
            <TooltipButton tip="Open file" disabled={!downloadable}>
              <div className="file-title" onClick={this.onPreview} data-cy="file-title">
                {attachment.filename}
              </div>
            </TooltipButton>

            <div className="spacer" />

            {interactive && (
              <DropdownDots
                pullRight
                id={`dd-attachment-${attachment.key}`}
                onSelect={this.handleAction}
                dataCyToggle="attachment-dd-actions"
              >
                <MenuItem eventKey="download" data-cy="download-attachment">
                  Download
                </MenuItem>
                <MenuItem eventKey="edit" data-cy="edit-attachment">
                  Edit metadata
                </MenuItem>
                <MenuItem eventKey="delete" data-cy="delete-attachment">
                  Delete
                </MenuItem>
              </DropdownDots>
            )}
          </div>

          {attachment.attachmentType === ATTACHMENT_TYPE.VARIABLE && (
            <div className="file-meta variableID" data-cy="file-meta-variable">
              <Icon name="variable" />
              <span>Variable: {attachment.variableID}</span>
            </div>
          )}

          <div className="file-meta date" data-cy="file-meta-date">
            <Icon name="calendar" />
            <span>{this.date}</span>
          </div>

          {attachment.description && (
            <div className="file-meta description" data-cy="attachment-file-description">
              <Icon name="info" />
              <span>{attachment.description}</span>
            </div>
          )}
        </div>
        <a className="d-none" ref={this.downloadRef} href={attachmentURL} target="_blank">
          {attachment.filename}
        </a>
      </DealPanelItem>
    );
  }
}
